import { Link } from "react-router-dom";
import "./wall.css";

export default function Wall() {
  return (
    <div className="backgroundWall">
      <div className="container">
        <div className="container-footerWall">
          <img src="/images/wall/18.png" alt="Más 18" />
          <div>
            <h1>Age Verification</h1>
            <hr className="container-footerWall-line" />
          </div>
        </div>
        <div className="container-bodyWall">
          <p className="text">This website is restricted.</p>
          <p className="text">
            If you’re not over 18 or you’re underage according to the laws of
            your country, you are not allowed to use this website or get access
            to its contents. <br />
            If you’re not over 18 or you’re underage according to the laws of
            your country, you are not allowed to use this website or get access
            to its contents.
          </p>
          <p className="text">
            If you’re over 18 or you’re not underage according to the laws of
            your country, you automatically agree to the{" "}
            <Link className="link" to="/terms" target="_blank">
              TERMS AND CONDITIONS
            </Link>{" "}
            of this website. <br />
            You also agree explicit sexual content and nudity is not offensive
            to you. <br />
            When you click on the “I'm older than 18 years old” button and get
            into the website, you agree with the statements above and certify,
            under penalty, that you are an adult.
          </p>
          <p>
            {" "}
            <Link className="container-bodyWall-button" to="/home">
              I'm older than 18 years old
            </Link>
          </p>
          <p className="container-bodyWall-under18">
            No, I am under 18 years old
          </p>
        </div>
      </div>
    </div>
  );
}
