import "./about-us.css";
import jsonHeroes from "../../data/heroes.json";

export default function AboutUs() {
  let heroesArray = jsonHeroes.Body;

  return (
    <>
      <p className="title" id="about-us">
        About Us
      </p>
      <div className="descriptionAboutUs">
        <p className="text">
          <b className="highlightedText">Kimochi Games</b> is a game studio
          where we make games with incredible stories by combining genres in a
          unique way. We have 6 years of experience in the industry and we have
          an awesome team that sees art in create games.
        </p>
        <p className="subTitle">Purpose</p>
        <p className="text">
          We create high quality video games with a great narrative approach
          that arouses pleasant experiences
        </p>
        <br />
        <div className="container_sc">
          {heroesArray.map((heroe) => (
            <div className="mob" key={heroe.Heroe}>
              <img src={heroe.LinkImage} alt="" />
              <p className="mobText">{heroe.Rol}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
