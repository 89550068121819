import "./join-community.css";

export default function JoinCommunity() {
  return (
    <>
      <p className="title" id="community">
        Join to our Community
      </p>
      <div className="descriptionJoinCommunity">
        <p className="text">
          People who love our video games are really important for us. That's
          why we want to keep in touch with you through various means of
          communication. This is our way to improve our games and to give you
          even better experiences. So, don't be shy and stay tuned for updates
          and support through our social media.
        </p>
      </div>
      <div className="container--sc">
        <div className="container_scFeed">
          <a
            className="twitter-timeline"
            data-width="300"
            data-height="500"
            data-chrome="nofooter noborders"
            href="https://twitter.com/KimochiVG?ref_src=twsrc%5Etfw"
          >
            Tweets by KimochiVG
          </a>
        </div>
        <div className="container_scFeed">
          <iframe
            src="https://discordapp.com/widget?id=442742586596261908&theme=dark"
            width="300"
            height="500"
            allowtransparency="true"
            frameBorder="0"
            title="Discord de Kimochi Games"
          ></iframe>
        </div>
      </div>
      <p className="subTitle2-community">Follow Us</p>
      <div className="redesSociales container_sc-community">
        <a
          href="https://www.facebook.com/KimochiVG/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/icono_facebook.png" alt="" />
        </a>
        <a
          href="https://twitter.com/KimochiVG"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/icono_twitter.png" alt="" />
        </a>
        <a
          href="https://www.instagram.com/kimochivg/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/icono_instagram.png" alt="" />
        </a>
      </div>
    </>
  );
}
