import AboutUs from "../../components/about-us/about-us";
import Footer from "../../components/footer/footer";
import Games from "../../components/games/games";
import Header from "../../components/header/header";
import JoinCommunity from "../../components/join-community/join-community";
import "./home.css";

export default function Home() {
  return (
    <div>
      <Header />
      <video
        className="videoIntroducctorio"
        autoPlay
        loop
        poster="/images/fondo_video.png"
      >
        <source src="/video/trailer.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Games />
      <AboutUs />
      <JoinCommunity />
      <Footer />
    </div>
  );
}
