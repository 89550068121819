import "./header.css";
import { useEffect, useState } from "react";

export default function Header() {
  const [inverseLogo, setinverseLogo] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".main-header");
    const scrollTop = window.scrollY;
    if (scrollTop >= 250) {
      header.classList.add("navbar-inverse");
      setinverseLogo(true);
    } else {
      setinverseLogo(false);
      header.classList.remove("navbar-inverse");
    }
  };
  return (
    <header className="main-header">
      <div className="topnav" id="myTopnav">
        {inverseLogo === true ? (
          <img
            src="/images/logo_kimochi_1.png"
            alt="Logo kimochi"
            className="logoKimochi active"
          />
        ) : (
          <img
            src="/images/logo_kimochi_2.png"
            alt="Logo kimochi"
            className="logoKimochi active"
          />
        )}

        <a href="/home#community">Join to Our Community</a>
        <a href="/home#about-us">About Us</a>
        <a href="/home#games">Games</a>
      </div>
    </header>
  );
}
