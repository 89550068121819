import "./App.css";
import { Routes, Route } from "react-router-dom";

//Paginas web
import Home from "./pages/home/home";
import Wall from "./pages/wall/wall";
import Terms from "./pages/terms/terms";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/*" element={<Wall />} />
        <Route path="/" element={<Wall />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </div>
  );
}

export default App;
