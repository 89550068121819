import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container_sc-footer">
        <div>
          <p>
            <a className="txt-pag" href="/home#games">
              Games
            </a>
          </p>
          <p>
            <a className="txt-pag" href="/home#about-us">
              About Us
            </a>
          </p>
          <p>
            <Link
              className="txt-pag"
              to="/terms"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </p>
        </div>
        <div>
          <p className="txt-redes-sociales ">
            <a
              href="https://twitter.com/KimochiVG"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </p>
          <p className="txt-redes-sociales ">
            <a
              href="https://www.instagram.com/kimochivg/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </p>
          <p className="txt-redes-sociales ">
            <a
              href="https://discord.gg/TuvVJwz"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
          </p>
        </div>
      </div>
      <p className="txt-Copy">Copyright @ Kimochi Games</p>
      <div className="logo-div">
        <img
          src="/images/logo_kimochi_1.png"
          alt="Logo kimochi"
          className="logoKimochi"
        />
      </div>
    </div>
  );
}
