import { Link } from "react-router-dom";
import "./terms.css";

export default function Terms() {
  return (
    <div className="container--privacy">
      <div className="container--header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="container--terms">
        <p className="text">
          The following Privacy Policy establishes the terms which
          <Link className="link" to="/">
            Kimochi Games SAS
          </Link>
          use and protect the information provided by users when they use our
          website. This company is committed to the security of the users data.
          When we ask you to fill the personal details fields which can be used
          to identify you, we assure that is done according to the terms of this
          document. However, this Privacy Policy can change through time or
          being updated for that reason we recommend you to check this website
          continuously to be sure you agree with such changes.
        </p>
        <h2>Use of the collected information</h2>
        <p className="text">
          Our website uses the details in order to provide the best service
          possible, particularly to keep users records, order if applied, and
          improve our products and services. It is possible that e mails could
          be sent frequently through our website with special offers, new
          products and other advertisements we consider relevant for you or that
          could bring you any sort of benefit, such emails will be sent to the
          e-mail address you provide and can be opted out at any moment.
        </p>
        <p className="text">
          <Link className="link" to="/">
            Kimochi Games SAS
          </Link>
          is highly committed to fulfill its commitment to keep your details
          safe. We use the most advanced systems and we update them regularly to
          be sure there is no unauthorized access.
        </p>
        <h2>Cookies</h2>
        <p className="text">
          A cookie refers to a file that is sent to request permission to be
          stored in your computer. When you accept the Privacy Policy, such a
          file is created and it works then to deal with information regarding
          web traffic and it also helps with future visits to a recurring web.
          Cookies can also help websites to recognize you individually and thus
          bring you the best personalized service of your web.
        </p>
        <p className="text">
          Our website uses cookies to be able to identify visited websites and
          their frequency. Such information is used only for statistical
          analysis and then this info is deleted permanently. You can delete
          cookies from your computer any time. However, cookies help to provide
          the best service possible from websites. They don’t grant access to
          your computer or your information, unless you want that and you
          provide it directly. You can accept or decline cookies usage,
          although, most browsers accept them automatically since that works for
          getting a better web service. Also, you can change your system set up
          to decline cookies. If you decline them, you might not be able to use
          some of our services.
        </p>
        <h2>Links to Third Party</h2>
        <p className="text">
          This website could have links to other websites that could be of your
          interest. Once you click on any of these links and quit our website,
          we will not have any control over the third party website that is
          restricted and hence we will not be responsible for the terms nor the
          privacy nor the protection of your data while you browse third party
          websites. Such websites will be subject to their own Privacy Policies
          therefore we recommend you reach them out to confirm you agree with
          them.
        </p>
        <h2>Personal details control</h2>
        <p className="text">
          You can restrict compiling or use of your personal details that is
          provided to our website at any time. Each time you are requested to
          fill out a form, e.g unsubscribe, you can mark or unmark the receiving
          newsletter option. If you have checked the newsletter or
          advertisements option you can cancel it at any time.
        </p>
        <p className="text">
          This company will not sell, give away or distribute your personal
          details that are collected without your consent, save it is requested
          by a judge in a court.
        </p>
        <p className="text">
          <Link className="link" to="/">
            Kimochi Games SAS
          </Link>
          well keep the right to change the Terms and Conditions of the current
          Privacy Policy any time.
        </p>
      </div>
    </div>
  );
}
