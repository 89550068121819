import "./games.css";
import jsonGames from "../../data/games.json";
export default function Games() {
  let gamesArray = jsonGames.Body;
  function parImpar(numero) {
    if (numero % 2 === 0) {
      return "par";
    } else {
      return "impar";
    }
  }
  return (
    <>
      {gamesArray.length > 0 ? (
        <div id="games">
          <p className="title">Games</p>
          {gamesArray.map((game) =>
            parImpar(game.Id) === "impar" ? (
              <div className="container_sc" key={game.GameName}>
                <img src={game.ImgLogo} alt="" className="logo logo-Impar" />
                <div className="description">
                  <p className="subTitle">{game.GameName}</p>
                  <hr className="horizontalLineImpar" />
                  <p className="text">{game.Description}</p>
                  {game.Posted === true ? (
                    <>
                      <p className="subTitle2">Play Now!</p>
                      <div className="container_sc buttonsArea">
                        {game.NSFWStore.map((store) => (
                          <a
                            href={store.Link}
                            target="_blank"
                            className="storeButton"
                            key={store.Link}
                            rel="noreferrer"
                          >
                            <img
                              src={store.Store.Image}
                              alt=""
                              className="storeIcon"
                            />
                          </a>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="subTitle2">Coming Soon!</p>
                      {game.webPage.map((webPage) => (
                        <a
                          href={webPage.Link}
                          target="_blank"
                          className="storeButton"
                          key={webPage.Link}
                          rel="noreferrer"
                        >
                          <img
                            src={webPage.Image}
                            alt=""
                            className="storeIcon"
                          />
                        </a>
                      ))}
                    </>
                  )}
                </div>
                <img src={game.Img} alt="" className="imageImpar" />
              </div>
            ) : (
              <div className="container_sc" key={game.GameName}>
                <img src={game.Img} alt="" className="imagePar" />
                <div className="description">
                  <p className="subTitle alinearDerecha">{game.GameName}</p>
                  <hr className="horizontalLinePar" />
                  <p className="text alinearDerecha">{game.Description}</p>

                  {game.Posted === true ? (
                    <>
                      <p className="subTitle2 alinearDerecha">Play Now!</p>
                      <div className="container_sc buttonsArea">
                        {game.NSFWStore.map((store) => (
                          <a
                            href={store.Link}
                            target="_blank"
                            className="storeButton"
                            key={store.Link}
                            rel="noreferrer"
                          >
                            <img
                              src={store.Store.Image}
                              alt=""
                              className="storeIcon"
                            />
                          </a>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="subTitle2 alinearDerecha">Coming Soon!</p>
                      {game.webPage.map((webPage) => (
                        <a
                          href={webPage.Link}
                          target="_blank"
                          className="storeButton"
                          key={webPage.Link}
                          rel="noreferrer"
                        >
                          <img
                            src={webPage.Image}
                            alt=""
                            className="storeIcon"
                          />
                        </a>
                      ))}
                    </>
                  )}
                </div>
                <img src={game.ImgLogo} alt="" className="logo logo-Par"></img>
              </div>
            )
          )}
        </div>
      ) : (
        <h1>Loading data...</h1>
      )}
    </>
  );
}
